exports.components = {
  "component---src-data-pages-404-index-jsx": () => import("./../../../src/data/pages/404/index.jsx" /* webpackChunkName: "component---src-data-pages-404-index-jsx" */),
  "component---src-data-pages-index-jsx": () => import("./../../../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-kde-kupit-index-jsx": () => import("./../../../src/data/pages/kde_kupit/index.jsx" /* webpackChunkName: "component---src-data-pages-kde-kupit-index-jsx" */),
  "component---src-data-pages-kontakt-index-jsx": () => import("./../../../src/data/pages/kontakt/index.jsx" /* webpackChunkName: "component---src-data-pages-kontakt-index-jsx" */),
  "component---src-data-pages-lieky-index-jsx": () => import("./../../../src/data/pages/lieky/index.jsx" /* webpackChunkName: "component---src-data-pages-lieky-index-jsx" */),
  "component---src-data-pages-lieky-olynth-01-index-jsx": () => import("./../../../src/data/pages/lieky/olynth_01/index.jsx" /* webpackChunkName: "component---src-data-pages-lieky-olynth-01-index-jsx" */),
  "component---src-data-pages-lieky-olynth-05-index-jsx": () => import("./../../../src/data/pages/lieky/olynth_05/index.jsx" /* webpackChunkName: "component---src-data-pages-lieky-olynth-05-index-jsx" */),
  "component---src-data-pages-lieky-olynth-ha-01-index-jsx": () => import("./../../../src/data/pages/lieky/olynth_ha_01/index.jsx" /* webpackChunkName: "component---src-data-pages-lieky-olynth-ha-01-index-jsx" */),
  "component---src-data-pages-lieky-olynth-ha-05-index-jsx": () => import("./../../../src/data/pages/lieky/olynth_ha_05/index.jsx" /* webpackChunkName: "component---src-data-pages-lieky-olynth-ha-05-index-jsx" */),
  "component---src-data-pages-lieky-olynth-plus-01-index-jsx": () => import("./../../../src/data/pages/lieky/olynth_plus_01/index.jsx" /* webpackChunkName: "component---src-data-pages-lieky-olynth-plus-01-index-jsx" */),
  "component---src-data-pages-lieky-olynth-plus-05-index-jsx": () => import("./../../../src/data/pages/lieky/olynth_plus_05/index.jsx" /* webpackChunkName: "component---src-data-pages-lieky-olynth-plus-05-index-jsx" */),
  "component---src-data-pages-mapa-stranok-index-jsx": () => import("./../../../src/data/pages/mapa-stranok/index.jsx" /* webpackChunkName: "component---src-data-pages-mapa-stranok-index-jsx" */),
  "component---src-data-pages-nadcha-index-jsx": () => import("./../../../src/data/pages/nadcha/index.jsx" /* webpackChunkName: "component---src-data-pages-nadcha-index-jsx" */),
  "component---src-data-pages-nadcha-u-deti-index-jsx": () => import("./../../../src/data/pages/nadcha_u_deti/index.jsx" /* webpackChunkName: "component---src-data-pages-nadcha-u-deti-index-jsx" */),
  "component---src-data-pages-otazky-a-odpovede-index-jsx": () => import("./../../../src/data/pages/otazky_a_odpovede/index.jsx" /* webpackChunkName: "component---src-data-pages-otazky-a-odpovede-index-jsx" */),
  "component---src-data-pages-pravne-oznamenie-index-jsx": () => import("./../../../src/data/pages/pravne_oznamenie/index.jsx" /* webpackChunkName: "component---src-data-pages-pravne-oznamenie-index-jsx" */),
  "component---src-data-pages-priloha-a-index-jsx": () => import("./../../../src/data/pages/priloha_A/index.jsx" /* webpackChunkName: "component---src-data-pages-priloha-a-index-jsx" */),
  "component---src-data-pages-radca-deti-ako-naucit-dieta-siakat-index-jsx": () => import("./../../../src/data/pages/radca/deti/ako_naucit_dieta_siakat/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-deti-ako-naucit-dieta-siakat-index-jsx" */),
  "component---src-data-pages-radca-deti-boj-s-infekciami-nadcha-a-kasel-u-dietata-index-jsx": () => import("./../../../src/data/pages/radca/deti/Boj_s_infekciami_nadcha_a_kasel_u_dietata/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-deti-boj-s-infekciami-nadcha-a-kasel-u-dietata-index-jsx" */),
  "component---src-data-pages-radca-deti-imunita-dietata-dbajte-a-posilnujte-index-jsx": () => import("./../../../src/data/pages/radca/deti/imunita_dietata_dbajte_a_posilnujte/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-deti-imunita-dietata-dbajte-a-posilnujte-index-jsx" */),
  "component---src-data-pages-radca-deti-upchaty-nos-u-det-c-3-ad-index-jsx": () => import("./../../../src/data/pages/radca/deti/upchaty_nos_u_detC3AD/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-deti-upchaty-nos-u-det-c-3-ad-index-jsx" */),
  "component---src-data-pages-radca-index-jsx": () => import("./../../../src/data/pages/radca/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-index-jsx" */),
  "component---src-data-pages-radca-nadcha-ako-si-spravne-cistit-nos-index-jsx": () => import("./../../../src/data/pages/radca/nadcha/ako_si_spravne_cistit_nos/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-nadcha-ako-si-spravne-cistit-nos-index-jsx" */),
  "component---src-data-pages-radca-nadcha-alergicka-nadcha-spoznajte-ju-a-predchadzajte-jej-index-jsx": () => import("./../../../src/data/pages/radca/nadcha/alergicka_nadcha_spoznajte_ju_a_predchadzajte_jej/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-nadcha-alergicka-nadcha-spoznajte-ju-a-predchadzajte-jej-index-jsx" */),
  "component---src-data-pages-radca-nadcha-co-je-nadcha-index-jsx": () => import("./../../../src/data/pages/radca/nadcha/co_je_nadcha/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-nadcha-co-je-nadcha-index-jsx" */),
  "component---src-data-pages-radca-nadcha-co-je-zapal-prinosovych-dutin-index-jsx": () => import("./../../../src/data/pages/radca/nadcha/co_je_zapal_prinosovych_dutin/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-nadcha-co-je-zapal-prinosovych-dutin-index-jsx" */),
  "component---src-data-pages-radca-nadcha-myty-o-nadche-index-jsx": () => import("./../../../src/data/pages/radca/nadcha/myty_o_nadche/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-nadcha-myty-o-nadche-index-jsx" */),
  "component---src-data-pages-radca-nadcha-nasledky-neliecenej-nadchy-index-jsx": () => import("./../../../src/data/pages/radca/nadcha/nasledky_neliecenej_nadchy/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-nadcha-nasledky-neliecenej-nadchy-index-jsx" */),
  "component---src-data-pages-radca-nadcha-spoznajte-typy-nadchy-index-jsx": () => import("./../../../src/data/pages/radca/nadcha/spoznajte_typy_nadchy/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-nadcha-spoznajte-typy-nadchy-index-jsx" */),
  "component---src-data-pages-radca-nadcha-zabojujte-s-nadchou-rychlo-index-jsx": () => import("./../../../src/data/pages/radca/nadcha/zabojujte_s_nadchou_rychlo/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-nadcha-zabojujte-s-nadchou-rychlo-index-jsx" */),
  "component---src-data-pages-radca-nadcha-zvladnite-zapal-prinosovych-dutin-index-jsx": () => import("./../../../src/data/pages/radca/nadcha/zvladnite_zapal_prinosovych_dutin/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-nadcha-zvladnite-zapal-prinosovych-dutin-index-jsx" */),
  "component---src-data-pages-radca-prechladnutie-ako-sa-ochranit-pred-prechladnutim-index-jsx": () => import("./../../../src/data/pages/radca/prechladnutie/ako_sa_ochranit_pred_prechladnutim/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-prechladnutie-ako-sa-ochranit-pred-prechladnutim-index-jsx" */),
  "component---src-data-pages-radca-prechladnutie-domace-sposoby-boja-s-prechladnutim-index-jsx": () => import("./../../../src/data/pages/radca/prechladnutie/domace_sposoby_boja_s_prechladnutim/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-prechladnutie-domace-sposoby-boja-s-prechladnutim-index-jsx" */),
  "component---src-data-pages-radca-prechladnutie-styri-fazy-vzniku-prechladnutia-index-jsx": () => import("./../../../src/data/pages/radca/prechladnutie/styri_fazy_vzniku_prechladnutia/index.jsx" /* webpackChunkName: "component---src-data-pages-radca-prechladnutie-styri-fazy-vzniku-prechladnutia-index-jsx" */),
  "component---src-data-pages-slovnik-pojmov-index-jsx": () => import("./../../../src/data/pages/slovnik_pojmov/index.jsx" /* webpackChunkName: "component---src-data-pages-slovnik-pojmov-index-jsx" */),
  "component---src-data-pages-televizna-reklama-index-jsx": () => import("./../../../src/data/pages/televizna_reklama/index.jsx" /* webpackChunkName: "component---src-data-pages-televizna-reklama-index-jsx" */),
  "component---src-data-pages-televizna-reklama-old-index-jsx": () => import("./../../../src/data/pages/televizna_reklama_old/index.jsx" /* webpackChunkName: "component---src-data-pages-televizna-reklama-old-index-jsx" */),
  "component---src-data-pages-upravte-liek-index-jsx": () => import("./../../../src/data/pages/upravte_liek/index.jsx" /* webpackChunkName: "component---src-data-pages-upravte-liek-index-jsx" */),
  "component---src-data-pages-zasady-ochrany-osobnych-udajov-index-jsx": () => import("./../../../src/data/pages/zasady_ochrany_osobnych_udajov/index.jsx" /* webpackChunkName: "component---src-data-pages-zasady-ochrany-osobnych-udajov-index-jsx" */),
  "component---src-data-pages-zasady-pouzivania-suborov-cookies-index-jsx": () => import("./../../../src/data/pages/zasady_pouzivania_suborov_cookies/index.jsx" /* webpackChunkName: "component---src-data-pages-zasady-pouzivania-suborov-cookies-index-jsx" */)
}

